import './index.scss';
// import firstWrapper from '../../assets/first.png';
import firstWrapper from '../../assets/R.png';

import orderlyLogo from '../../assets/orderlyLogo.svg';
import {Button} from '../Button/index';

export const First = () => {
  return (
    <div className="first container">
      <div className="first__left">
        <h1>
            Venturing into DeFi and Beyond
        </h1>
        <p>
            Discovering New Frontiers in Blockchain Technology
        </p>
        <div className='first__left--buttons'>
          <Button className="first__left--button1" text="APPLY NOW" to="https://forms.gle/ThhKdKQxsfQHASZ29" />
          
          <a href="https://www.surveycake.com/s/mQwpx" target="_blank" className='first__left--research ignore'><div>HACK TAIPEI HACKATHON</div> </a>
          {/* <a className='first__left--research ignore' href='https://medium.com/@orderlynetwork/decoding-crypto-prime-brokerage-a-comprehensive-overview-of-tradfi-comparisons-major-players-5ebc006329f2' target="_blank">
            VIEW RESEARCH
            <span>NEW</span>
          </a> */}
        </div>
        <div className='first__left--powered'>
            <span>Powered by</span> <img src={orderlyLogo} alt=""/>
        </div>
      </div>
      <div className="first__right">
        <img src={firstWrapper} alt="" />
      </div>
    </div>
  );
}
