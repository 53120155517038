import './index.scss';
import { Title } from '../Title';
import image1 from '../../assets/economic.png';
import image2 from '../../assets/expert.png';
import image3 from '../../assets/market.png';
import image4 from '../../assets/growth.png';
import image5 from '../../assets/marketing.png';
import image6 from '../../assets/trading2.png';

export const Third = () => {
  return (
    <div className="third container" id="discover">
    <Title label="Discover" title="Discover our Advantages" maxWidth={860}/>
    <div className='third__content'>
        <p className='third__content--desc'>Joining the Radiance Ventures opens the door to a suite of benefits tailored to propel your project to success.</p>
        <div className='third__content--body'>
            <div className='third__content--body__list'>
              <div className='third__content--body__list--item'>
                <img src={image1} alt=""/>
                <h3 className='third__content--body__list--item__title'>
                  Economic Modeling
                </h3>
                <div className='third__content--body__list--item__text'>
                  Leverage our expertise in token economics, equipping you with strategic insights to successfully maneuver within the intricate world of digital currencies.
                </div>
              </div>
              <div className='third__content--body__list--item'>
                <img src={image2} alt=""/>
                <h3 className='third__content--body__list--item__title'>
                  Expert Guidance
                </h3>
                <div className='third__content--body__list--item__text'>
                  Our team of seasoned professionals is ready to help you navigate the unique challenges of the blockchain landscape.
                </div>
              </div>
              <div className='third__content--body__list--item onlyTablet'>
                <img src={image6} alt=""/>
                <h3 className='third__content--body__list--item__title'>
                Trading Proficiency
                </h3>
                <div className='third__content--body__list--item__text'>
                Benefit from our broad and rich trading experience, in maneuvering through the intricate and volatile crypto markets.
                </div>
              </div>
            </div>
            <div className='third__content--body__list secondList'>
            <div className='third__content--body__list--item'>
                <img src={image3} alt=""/>
                <h3 className='third__content--body__list--item__title'>
                  Market Making Advisory
                </h3>
                <div className='third__content--body__list--item__text'>
                  Leverage our extensive network of international market-makers.
                </div>
              </div>
              <div className='third__content--body__list--item'>
                <img src={image4} alt=""/>
                <h3 className='third__content--body__list--item__title'>
                  Growth Support
                </h3>
                <div className='third__content--body__list--item__text'>
                  Leverage our extensive industry connections to accelerate your growth trajectory.
                </div>
              </div>
              <div className='third__content--body__list--item onlyTablet'>
                <img src={image5} alt=""/>
                <h3 className='third__content--body__list--item__title'>
                  Marketing Exposure
                </h3>
                <div className='third__content--body__list--item__text'>
                  Amplify your brands reach through our extensive marketing channels.
                </div>
              </div>
            </div>
            <div className='third__content--body__list thirdList'>
            <div className='third__content--body__list--item'>
                <img src={image5} alt=""/>
                <h3 className='third__content--body__list--item__title'>
                  Marketing Exposure
                </h3>
                <div className='third__content--body__list--item__text'>
                  Amplify your brands reach through our extensive marketing channels.
                </div>
              </div>
              <div className='third__content--body__list--item'>
                <img src={image6} alt=""/>
                <h3 className='third__content--body__list--item__title'>
                Trading Proficiency
                </h3>
                <div className='third__content--body__list--item__text'>
                Benefit from our broad and rich trading experience, in maneuvering through the intricate and volatile crypto markets.
                </div>
              </div>
            </div>
        </div>
    </div>
  </div>
  );
}
