
import { useEffect } from "react";
import logo from '../assets/Logo.svg'
import { Button } from "../components/Button";

export const UnavailablePage = () => {

    useEffect(() => {
        document.querySelector('body').classList.add('unavailable');

        return () => {
            document.querySelector('body').classList.remove('unavailable');
        }
    }, [])
  return (
    <div className="unknownPage">
      <div className="container">
        <img src={logo} />
        <div>
          <h2>404 Error</h2>
          <p>The page you are looking for cannot be found.</p>
          <Button text="Go to homepage" to="/" current={true} />
        </div>
      </div>
      <div className="unknownPage__gradient"></div>
    </div>
  );
}