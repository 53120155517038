import './index.scss';
import { Title } from '../Title';
import { Button } from '../Button';

export const Fifth = () => {
  return (
    <div className="fifth container">
      <div className='fifth__header'>
        <Title label="Contact us" title="Let's Build the Future Together" maxWidth={570}/>
        <Button text="Apply now" to="https://forms.gle/ThhKdKQxsfQHASZ29" />
      </div>
    </div>
  );
}
