import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Home } from './pages/home.js';
import { Terms } from './pages/terms.js';
import { UnavailablePage } from './pages/404.js';


export const RoutesList = () => (
  <Routes>
    <Route path={'/'} element={<Home />} />
    <Route path={'/terms'} element={<Terms />} />
    <Route path="*" element={<UnavailablePage />} />
  </Routes>
);
