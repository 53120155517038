import { useEffect } from "react";

export const Terms = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
    <div className="terms container">
        <div className="terms__title">
            Radiance Ventures Terms of Use
        </div>
        <div className="terms__date">
            Date: June 2023
        </div>
        <div>
            <p>
            Radiance Ventures is part of Orderly Network Ltd., and this Radiance Ventures Terms of Use (the “Terms”) apply to your access to and use of the websites, platform, software, technologies, features, and other online products and services (collectively, the “Radiance Services”) provided or made available by Radiance Ventures of Orderly Network Ltd. (“Company” “Orderly” or “we”) in connection with the Radiance Ventures service or partnership (“Program”). 
            </p>
            <h2>
                1.     Agreement to Terms 
            </h2>
            <p>
            By accessing or using our Radiance Services or by participating in our Program, you agree to these Terms and any other agreements or terms as set forth by the Company, including the Orderly Network Terms of Use as posted and updated on our website. Unless provided or mutually agreed otherwise, these Terms constitute a binding obligation between you and the Company.  If you do not agree to these Terms, do not use our Radiance Services. 
            </p>
            <p>YOUR PARTICIPATION IN PROGRAM IS ENTIRELY VOLUNTARY, BUT IF YOU ARE PARTICIPATING IN PROGRAM, YOU MUST STRICTLY ADHERE TO THE TERMS.</p>
            <p>
            NOTICE: Please read these Terms carefully as it governs your use of the Program. These Terms contain important information, including a binding arbitration provision and a class action waiver, both of which impact your rights as to how disputes are resolved. 
            </p>
            <h2>2.     Privacy Policy</h2>
            <p>
            This Privacy Policy (hereinafter "Policy") constitutes a part of the Terms used in this Policy shall have the same meanings as in the Terms except as otherwise provided herein. This Policy explains how we collect, use, process, disclose, share, transfer, and protect personal information obtained through us and our partners. This Policy also describes how we comply with our legal obligations to you, and how we respect your ability to know, access, correct, transfer, restrict the processing of, and delete, your data. By using our platform, you agree to the collection, use, storage, and disclosure of your data in accordance with our Privacy Policy.  
            </p>
            <h3>2.1  Applicability of this Policy</h3>
            <p>
            This Policy applies to our services, which includes but not limited to the services we provide on any other websites, pages, features, or content we own or operating third party applications relying on such an API, and related services. 
            </p>
            <h3>2.2  Safety and Security</h3>
            <p>
                We may use acquired information to help maintain the safety, security, and integrity of you and our services.
            </p>
            <h2>3.     Changes to Terms</h2>
            <p>
            The Company may update the Terms at any time, in its sole discretion. If it does so, the Company will deliver a notice to the Participator in due course. If you continue to participate in the Program after the Company has delivered updated Terms, you are agreeing to be bound by the updated Terms. If you don’t agree to be bound by the updated Terms, then you may not participate in the Program anymore. 
            </p>
            <h2>4.     Duration of the Terms</h2>
            <p>
            The Program will commence on the date prescribed by the Company and continue until terminated by the Company in its sole discretion (“Program Period”).  Notwithstanding any other information provided by the Company regarding the Program (including on its website, blog posts or through other communications (such as forums, Telegram, Discord, or other channels), the Company may change, discontinue, or terminate, temporarily or permanently, all or any part of Program, at any time and without notice, at its sole discretion (including prior to providing any incentives or rewards). 
            </p>
            <h2>5.     Eligibility for Program</h2>
            <p>
            YOU MAY PARTICIPATE IN PROGRAM ONLY IF YOU ARE NOT SUBJECT OF SANCTIONS ADMINISTERED OR ENFORCED BY ANY COUNTRY OR GOVERNMENT OR OTHERWISE DESIGNATED ON ANY LIST OF PROHIBITED OR RESTRICTED PARTIES (INCLUDING BUT NOT LIMITED TO THE LISTS MAINTAINED BY THE UNITED NATIONS SECURITY COUNCIL, THE U.S. GOVERNMENT, THE EUROPEAN UNION OR ITS MEMBER STATES, OR OTHER APPLICABLE GOVERNMENT AUTHORITY) OR A CITIZEN OR ORGANIZED OR RESIDENT IN A COUNTRY OR TERRITORY THAT IS THE SUBJECT OF COUNTRY-WIDE OR TERRITORY-WIDE SANCTIONS (INCLUDING, WITHOUT LIMITATION, CUBA, DEMOCRATIC PEOPLE’S REPUBLIC OF KOREA IRAN); AND (C) YOU ARE NOT BARRED FROM PARTICIPATING UNDER APPLICABLE LAW. IN ADDITION, TO BE ELIGIBLE FOR ANY INCENTIVES OR REWARDS AS PART OF THE PROGRAM (WHERE APPLICABLE), YOU (I) MUST BE A NATURAL PERSON LEGAL ENTITY, (II) MUST NOT BE A U.S. PERSON OR CITIZEN, AND (III) MUST NOT BE AN EMPLOYEE, CONSULTANT OR AFFILIATE OF THE COMPANY. 
            </p>
            <h2>6.     Third-Party Services</h2>
            <p>
            The Company may share identifying information and documentation with certain vendors or third-party providers who provide such identity verification and sanctions and watchlist screening services (including without limitation Persona) (the “Third-Party Services”).  You agree that your access and use of such Third Party Services is governed solely by the terms and conditions of such Third Party Services, and the Company is not responsible or liable for, and make no representations as to any aspect of such Third Party Services, including, without limitation, their content or the manner in which they handle, protect, manage or process data or any interaction between you and the provider of such Third Party Services. You irrevocably waive any claim against the Company with respect to such Third-Party Services. We are not liable for any damage or loss caused or alleged to be caused by or in connection with your enablement, access or use of any such Third-Party Services, or your reliance on the privacy practices, data security processes or other policies of such Third-Party Services. 
            </p>
            <h2>
                7.     Rewards/Incentives 
            </h2>
            <p>
            As part of your participation in Program, the Company may choose, in its sole discretion, to provide certain incentives or rewards to eligible participants for completing certain requirements set by the Company.
            Details of the requirements and incentives/reward may be separately agreed between you and the Company. 
            </p>

            <p>
                Notwithstanding any other information provided by the Company regarding the Program (including on its website, blog posts or through other communications (such as forums, Telegram, Discord, or other channels), the Company may in its discretion change or modify the amounts or types of rewards or incentives and/or discontinue, or terminate, temporarily or permanently, all or any part of the incentives or rewards program at any time and without notice and the Company may remove or reallocate any rewards or incentives earned by any participant or elect not to provide any rewards to any participant.
            </p>
            <p>
                The Company reserves the right to block your access to Program or other Radiance Services.  If you violate any of the terms and conditions of the Terms (as determined solely by the Company) or if you do not complete the necessary information or fail to provide accurate information, then you will not be eligible to receive rewards.
                
            </p>
            <p>
                The Company reserves the right to request information about, review and investigate all Program activities, and to disqualify Participant if it believes the Participant has engaged in any activity that is abusive, fraudulent, in bad faith or otherwise fails to meet the Program standards and requirements. 
            </p>
            <p>
                You agree that any rewards or incentives received will be for your services in building on top of OrderlyNetwork.  
            </p>
            <h2>
                8.     Taxes
            </h2>
            <p>
            You are responsible for the payment of all taxes associated with your receipt of any rewards (including, if applicable, ORDER tokens). You agree to provide the Company with any additional information and complete any required tax or other forms relating to your receipt of such rewards.
            </p>
            <p>
                You may suffer adverse tax consequences as a result of your participation in the Program. You hereby represent that (i) you have consulted with a tax adviser that you deem advisable in connection with your participation, or that you have had the opportunity to obtain tax advice but have chosen not to do so, (ii) the Company has not provided you with any tax advice with respect to your participation, and (iii) you are not relying on the Company for any tax advice.
            </p>
            <h2>
                9.     Security; Wallet
            </h2>
            <p>
            You are responsible for maintaining adequate security and control of any and all IDs, passwords, hints, personal identification numbers (PINs), or any other codes that you use to access the Radiance Services as part of your systems. The Company assumes no responsibility for any losses resulting from any compromise of your systems or wallet(s).
            </p>
            <p>
                As part of certain phases of the Radiance Services or as part of providing incentives/rewards, the Company may send or receive ORDER tokens or rewards to your wallet application. In such a case, you are responsible for the management of the private keys and security for your wallet(s). The Company does not and will not manage, store, collect or otherwise access the private keys for your wallet(s). You’re responsible for all activities that occur using your wallet, whether or not you know about them.  
            </p>
            <h2>
            10.  Updates; Monitoring
            </h2>
            <p>
            We may make any improvement, modifications or updates to our website, including but not limited to changes and updates to the underlying software, infrastructure, security protocols, technical configurations or service features (the “Updates”) from time to time.  Your continued access and use of the Program are subject to such Updates and you shall accept any patches, system upgrades, bug fixes, feature modifications, or other maintenance work that arise out of such Updates.  We are not liable for any failure by you to accept and use such Updates in the manner specified or required by us.  Although the Company is not obligated to monitor access to or participation on our website, it has the right to do so for the purpose of operating our website, to ensure compliance with the Terms and to comply with applicable law or other legal requirements. 
            </p>
            <h2>
            11.  Intellectual Property Rights; Feedback
            </h2>
            <p>
            All right, title, and interest in and to the Program, including all modifications, improvements, and enhancements made thereto, and all proprietary rights therein, shall be and remain the Company’s sole and exclusive property.
            </p>
            <p>
                The Company welcomes feedback, comments and suggestions for improvements to the Program and related technologies of the Orderly protocol (“Feedback”). You grant to the Company a non-exclusive, transferable, worldwide, perpetual, irrevocable, fully-paid, royalty-free license, with the right to sublicense, under any and all intellectual property rights that you own or control to use, copy, modify, create derivative works based upon and otherwise exploit the Feedback for any purpose, in any form, format, media or media channels now known or later developed or discovered.
            </p>
            <h2>
                12.  Prohibited Activities
            </h2>
            <p>
                You will not (in each case except as otherwise contemplated as part of these Terms):
            </p>
            <ul>
                <li>
                    12.1 Initiate any attacks that violate your cloud service, data-center or other applicable third party service provider’s rules or policies;
                </li>
                <li>
                    12.2 Unless as expressly requested by the Company as part of a security or similar challenge, disrupt or initiate any attacks against users of the Radiance Services or the Program;
                </li>
                <li>
                    12.3 Disrupt, compromise, or otherwise damage data or property owned by other parties;
                </li>
                <li>
                    12.4 Impersonate any person or entity or misrepresent your affiliation with any person or entity;
                </li>
                <li>
                    12.5 Access any accounts or data other than your own (or those for which you have explicit, permission from their owners); 
                </li>
                <li>
                    12.6 Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending a virus, overloading, flooding, spamming, creating, encouraging or implementing Sibyl attacks;
                </li>
                <li>
                    12.7 Sell or resell Radiance Services; 
                </li>
                <li>
                12.8 Participate in the Program from a jurisdiction other than the jurisdiction of your residence (as determined by the identity documentation provided by you); and
                   <br></br> Encourage or enable any other individual or entity to do any of the foregoing or otherwise violate the Terms.
                </li>
                </ul>
                <h2>
                13.  Compliance with Laws
                </h2>
                <p>
                You agree that to comply with all applicable laws, rules, regulations, and any generally accepted practices or guidelines in the relevant jurisdictions in connection with your participation with the Program. To the extent required by law, you are solely responsible for obtaining or filing any approval, clearance, registration, permit, or other regulatory authorization and shall comply with the requirements of such authorization.
                </p>
                <h2>
                14.  WARRANTY DISCLAIMERS
                </h2>
                <p>
                    THE COMPANY MAKES NO, AND HEREBY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND WITH RESPECT TO OUR SERVICES. THE SERVICES  ARE PROVIDED “AS IS” AND WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, THE COMPANY EXPLICITLY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, VALUE, QUIET ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.  The Company has made no promises or guarantees with respect to delivery of any future features or functions. Any discussions of any future features or functions, either prior to or following the agreement to these Terms, are for informational purposes only, and the Company has no obligation to provide any such features or functions. You acknowledge that you have not relied on any statement, promise, assurance, or warranty that is not set out in the Terms.
                </p>
                <p>
                    YOU ACCEPT AND ACKNOWLEDGE THAT THERE ARE RISKS ASSOCIATED WITH PARTICIPATING IN THE PROGRAM, INCLUDING, BUT NOT LIMITED TO, THE RISK OF FAILURE OF HARDWARE, SOFTWARE AND INTERNET CONNECTIONS, THE RISK OF MALICIOUS SOFTWARE INTRODUCTION, LOSS OF REWARDS, AND SECURITY RISKS. YOU ACCEPT AND ACKNOWLEDGE THAT THE COMPANY WILL NOT BE RESPONSIBLE FOR ANY LOSSES, FAILURES, DISRUPTIONS, ERRORS, DISTORTIONS OR DELAYS YOU MAY EXPERIENCE WHEN PARTICIPATING IN THE PROGRAM, HOWEVER CAUSED.  THE COMPANY WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND TAKES NO RESPONSIBILITY FOR AND WILL NOT BE LIABLE TO YOU FOR YOUR PARTICIPATION IN THE PROGRAM.
                </p>
                <p>
                    The Company makes no warranty that Program will meet your requirements or be available on an uninterrupted, secure, or error-free basis or free of viruses, errors, or defects. The Company makes no warranty regarding the quality, accuracy, timeliness, truthfulness, completeness or reliability of any information or materials offered in connection with the Program. The value of any rewards or incentives you received (including any tokens) may vary, and the Company is not responsible for any value change of rewards or incentives granted or to be granted to you.
                </p>
                <ul>
                    <li>You further understand and agree that:</li>
                    <li>
                        1)    access to the Program may require the use of your personal computer and/or mobile devices, as well as communications with or use of data and storage on such devices. You are responsible for any internet connection or mobile fees and charges that you may incur as part of your voluntary participation in the Program.
                    </li>
                    <li>
                        2)    certain information, including your on-chain transaction records, are all public information and can be accessed by anyone, including participants and non-participants of the Program.
                    </li>
                    <li>
                        3)    security and other blockchain audits undertaken by the Company or third parties will access user data and could result in the loss of data or provoke actions from telecommunications and other third party service providers. 
                    </li>
                </ul>
                <h2>
                    15.  LIMITATION OF LIABILITY
                </h2>
                <p>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL WE OR OUR AFFILIATES, SUPPLIERS OR LICENSORS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, OR FOR LOSS OF USE, LOST PROFITS, OR LOSS OF DATA ARISING OUT OF OR RELATED TO THESE TERMS OR THE PROGRAM OR THE RADIANCE SERVICES, HOWEVER CAUSED AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, EVEN IF WE OR OUR SUPPLIERS OR LICENSORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE CUMULATIVE LIABILITY OF US, OUR SUPPLIERS, AND OUR LICENSORS FOR ANY CLAIMS ARISING OUT OF OR RELATED TO THESE TERMS OR THE PROGRAM OR THE RADIANCE SERVICES EXCEED ONE HUNDRED US DOLLARS ($100). Some jurisdictions do not allow limitations of liability, so the foregoing limitation may not be applicable to you.
                </p>
                <p>
                THE EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN THE COMPANY AND YOU. 
                </p>
                <h2>
                    16.  Indemnification
                </h2>
                <p>
                To the fullest extent permitted by applicable law, you will indemnify, defend and hold harmless Company and our subsidiaries and affiliates, and each of our respective officers, directors, agents, partners and employees (individually and collectively, the “Company Parties”) from and against any losses, liabilities, claims, demands, damages, expenses or costs (“Claims”) arising out of or related to (a) your access to or use of the Radiance Services or the Program; (b) your violation of these Terms; (c) your violation, misappropriation or infringement of any rights of another (including intellectual property rights or privacy rights); or (d) your conduct in connection with the Radiance Services or your participation in the Program. You agree to promptly notify the Company Parties of any third-party Claims, cooperate with the Company Parties in defending such Claims and pay all fees, costs and expenses associated with defending such Claims (including attorneys’ fees). You also agree that the Company Parties will have control of the defense or settlement, at Company’s sole discretion, of any third-party Claims. 
                </p>
                <h2>
                17.  Confidentiality
                </h2>
                <p>
                You acknowledge that, in connection with your participation in the Program, you may be exposed to data and information, including product, technology, business, and strategy information that is confidential and proprietary to us (collectively, “Confidential Information”). All Confidential Information shall be sole and exclusive property of the Company and may be used by you only for assisting us in resolving any security issue you have reported to us. You may not reveal, publish, or otherwise disclose the Confidential Information to any third party without the prior written consent of the Company, and shall protect the Confidential Information from disclosure using the same degree of care you use to protect your own confidential information of like kind, but in no event using less than reasonable care. 
                </p>
                <h2>
                18.  Governing Law; Forum; Dispute Resolution
                </h2>
                <p>
                These Terms and any action related thereto will be governed by the laws of the Republic of China (the R.O.C)without regard to its conflict of laws provisions. The exclusive jurisdiction for all Disputes (defined below) will be in the city of Taipei, and you and Company each waive any objection to such jurisdiction and venue.
                </p>
                <p>
                    Any and all disputes between you, and including but not limited to your affiliates, shareholders, directors, officers, employees, agents, successors, attorneys and assigns, and the Company, including its affiliates, shareholders, directors, officers, employees, agents, successors, attorneys and/or assigns, relating in any manner whatsoever to this Terms ("Arbitral Claims"), and shall be resolved by and through the use of arbitration. Arbitration of such claims shall be done in accordance with the local laws of Republic of China (R.O.C), and shall be conducted within the jurisdiction in which the Company is located, or which designated by the Company. The fees associated with the arbitrator shall be shared equally by both parties. The parties agree that this paragraph shall survive the termination of this Terms. 
                </p>
                <h2>
                19.  Assignment
                </h2>
                <p>
                You may not sell, assign or transfer any of your rights, duties or obligations under these Terms without our prior written consent. Any attempt by you to assign or transfer the Terms, without such consent, will be null and void.  We reserve the right to assign or transfer these Terms or any of its rights, duties and obligations hereunder to any third party.   Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors and permitted assigns.  
                </p>
                <h2>
                20.  General Terms
                </h2>
                <p>
                Nothing herein shall constitute an employment, consultancy, joint venture, or partnership relationship between you and the Company.  If any provision of the Terms is held invalid or unenforceable by an arbitrator or a court of competent jurisdiction, that provision will be enforced to the maximum extent permissible and the other provisions of the Terms will remain in full force and effect.  These Terms reflect the entire agreement between the parties relating to the subject matter hereof and supersede all prior agreements, representations, statements and understandings of the parties. The section titles in these Terms are for convenience only and have no legal or contractual effect. Use of the word “including” will be interpreted to mean “including without limitation.” 
                </p>
                <h2>
                21.  Notices
                </h2>
                <p>
                Any notices or other communications provided by Company under the Terms, including those regarding modifications to the Terms, may be given by posting to the Radiance Ventures website, Orderly website, blog or through other communications (such as forums, Telegram, Discord, or other channels).
                </p>
                <h2>
                22.  Waiver of Rights 
                </h2>
                <p>
                Company’s failure to enforce any right or provision of these Terms  will NOT be considered a waiver of such right or provision. The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of Company. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise. 
                </p>
        </div>
    </div>
  );
}