import './index.scss';
import { Title } from '../Title';
import { useRef, useEffect } from 'react';
import team1 from '../../assets/team/team1.png'
import team3 from '../../assets/team/team3.png'
import team4 from '../../assets/team/team4.png'
import gsap from 'gsap';


export const Fourth = () => {

  const sceneRef = useRef(null);
  const imagePaths = [team1, team3, team4];

  useEffect(() => {
    // Create animations
    gsap.to('.image1', {
      y: '+=30',
      duration: 1.5,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    });

    gsap.to('.image2', {
      y: '-=25',
      duration: 1.5,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    });

    gsap.to('.image3', {
      y: '-=25',
      duration: 1.5,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    });

    // Cleanup: Stop animations and clean up event listeners
    return () => {
      gsap.killTweensOf('.image1');
    };
  }, []);

  return (
    <div className="fourth container" id="team">
      <Title label="Team" title="Team-Driven Innovations" maxWidth={900}/>
      <div className='fourth__content'>
        <div className='fourth__content--left'>
          <div className='teamScene' ref={sceneRef}>
          {imagePaths.map((path, index) => (
            <img key={index} src={path} alt={`Image ${index + 1}`} className={`image${index+1}`} />
          ))}
          </div>
        </div>
        <div className='fourth__content--right'>
          <div className='fourth__content--right__title'>
            We're partners, fervently dedicated to nurturing growth and igniting innovation within the blockchain ecosystem.
          </div>
          <div className='fourth__content--right__wrapper'>
            <p>
              The strength of Radiance Ventures rests on the bedrock of our extraordinary team. Our team unites seasoned builders from diverse international backgrounds, with proven track record from top-tier exchanges, L1 founders, or leading market makers. 
            </p>
            <p>
            At Radiance Ventures, we extend beyond the traditional investor role - we're partners, fervently dedicated to nurturing growth and igniting innovation within the blockchain ecosystem.
            </p>
            <p>
            <q>At Radiance Ventures, we're not just providing resources, we're building a foundation for outstanding DApps. With comprehensive support, a global team of proven builders, and a commitment to transparency, we're not just investing in a decentralized future - we're creating it.</q> - Audrey Yang, CGO, Orderly Network

            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
