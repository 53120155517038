import './index.scss';

export const Title = ({title, label, maxWidth}) => {
  return (
    <div className="title" style={{maxWidth: `${maxWidth}px`}}>
      <div className='title__label'>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="15" viewBox="0 0 13 15" fill="none">
        <path d="M12.4058 7.20288L0.10144 14.3068L0.10144 0.098977L12.4058 7.20288Z" fill="#4F95FF"/>
    </svg>
    <span>{label}</span>
    </div>
      <h2>{title}</h2>
    </div>
  );
}
