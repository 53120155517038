export const Button = ({text, to, current = false, className}) => {
  const goTo = () => {
    if (to && !current) {
      window.open(to)
    } else {
      window.location = '/'
    }
  }
  return (
    <div className={`button ${className || ''}`} onClick={goTo}>
        <div className="gradient gradient1"></div>
        <div className="gradient gradient2"></div>
        <span>{text}</span>
    </div>
  );
}
