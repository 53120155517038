import { First } from '../components/first';
import { Second } from '../components/second';
import { Third } from '../components/third';
import { Fourth } from '../components/fourth';
import { Fifth } from '../components/fifth';

export const Home = () => {
  return (
    <>
      <First />
      <Second />
      <Third />
      <Fourth />
      <Fifth />
    </>
  );
}