import './index.scss';
import { Title } from '../Title';
import about1 from '../../assets/about/about1.png';
import about2 from '../../assets/about/about2.png';
import about3 from '../../assets/about/about3.png';
import about4 from '../../assets/about/about4.png';
import about5 from '../../assets/about/about5.png';
import about6 from '../../assets/about/about6.png';
import about7 from '../../assets/about/about7.png';
import gsap from 'gsap';
import { useRef, useEffect } from 'react';

export const Second = () => {

  const sceneRef = useRef(null);
  const imagePaths = [about1, about2, about3, about4, about5, about6, about7];

  useEffect(() => {
    // Create animations
    gsap.to('.about_image1', {
      x: '+=25',
      y: '-=11',
      duration: 1.5,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    });

    gsap.to('.about_image2', {
      y: '+=21',
      duration: 1.5,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    });

    gsap.to('.about_image3', {
      x: '+=11',
      y: '-=20',
      rotate: '+5',
      duration: 1.5,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    });

    gsap.to('.about_image4', {
      y: '-=11',
      duration: 1.5,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    });

    gsap.to('.about_image5', {
      y: '+=31',
      duration: 1.5,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    });

    gsap.to('.about_image6', {
      x: '+=40',
      duration: 1.5,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    });

    gsap.to('.about_image7', {
      rotate: '+45',
      duration: 1.5,
      ease: 'power1.inOut',
      repeat: -1,
      yoyo: true,
    });
   

    // Cleanup: Stop animations and clean up event listeners
    return () => {
      gsap.killTweensOf('.image1');
    };
  }, []);

  return (
    <div className="second container" id="about">
      <Title label="About us" title="Building Blockchain Breakthroughs" maxWidth={680}/>
      <div className='second__content'>
        <div className='second__content--left'>
        <div className='aboutScene' ref={sceneRef}>
          {imagePaths.map((path, index) => (
            <img key={index} src={path} alt={`Image ${index + 1}`} className={`about_image${index+1}`} />
          ))}
        </div>
        </div>
        <div className='second__content--right'>
        <p>
          Powered by Orderly Network, Radiance Ventures accelerates blockchain advancements, reshapes digital asset interactivity, and spurs growth throughout Web3 landscapes.
        </p>
        <p>
          Leveraging Orderly's robust infrastructure, deep liquidity, rich industry experience, and broad-reaching Web3 network, Radiance Ventures strives to provide holistic support to founders, fueling the forward momentum of Web 3 innovation.
        </p>
        </div>
      </div>
    </div>
  );
}
